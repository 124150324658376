import { Editor, posToDOMRect, isNodeSelection } from '@tiptap/core';
import { EditorState, Plugin, PluginKey } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';
import tippy, { Instance, Props } from 'tippy.js';

export interface TableMenuPluginProps {
  editor: Editor;
  element: HTMLElement;
  tippyOptions?: Partial<Props>;
}

export type TableMenuViewProps = TableMenuPluginProps & {
  view: EditorView;
};

export class TableMenuView {
  public editor: Editor;

  public element: HTMLElement;

  public view: EditorView;

  public preventHide = false;

  public tippy!: Instance;

  constructor({ editor, element, view, tippyOptions }: TableMenuViewProps) {
    this.editor = editor;
    this.element = element;
    this.view = view;
    this.element.addEventListener('mousedown', this.mousedownHandler, { capture: true });
    this.view.dom.addEventListener('dragstart', this.dragstartHandler);
    this.editor.on('focus', this.focusHandler);
    this.editor.on('blur', this.blurHandler);
    this.createTooltip(tippyOptions);
    this.element.style.visibility = 'visible';
  }

  mousedownHandler = () => {
    this.preventHide = true;
  };

  dragstartHandler = () => {
    this.hide();
  };

  focusHandler = () => {
    // we use `setTimeout` to make sure `selection` is already updated
    setTimeout(() => this.update(this.editor.view));
  };

  blurHandler = ({ event }: { event: FocusEvent }) => {
    if (this.preventHide) {
      this.preventHide = false;
      return;
    }

    if (
      event?.relatedTarget &&
      this.element.parentNode?.contains(event.relatedTarget as Node)
    ) {
      return;
    }

    this.hide();
  };

  createTooltip(options: Partial<Props> = {}) {
    this.tippy = tippy(this.view.dom, {
      duration: 300,
      getReferenceClientRect: null,
      content: this.element,
      interactive: true,
      trigger: 'manual',
      placement: 'top',
      hideOnClick: 'toggle',
      ...options
    });
  }

  update(view: EditorView, oldState?: EditorState) {
    let { state, composing } = view;
    let { doc, selection } = state;
    let isSame = oldState && oldState.doc.eq(doc) && oldState.selection.eq(selection);

    if (composing || isSame) {
      return;
    }

    let { empty, ranges } = selection;

    // support for CellSelections
    let from = Math.min(...ranges.map(range => range.$from.pos));
    let to = Math.max(...ranges.map(range => range.$to.pos));

    let tableElement: HTMLTableElement | null = null;
    let element: HTMLElement | null = view.domAtPos(from).node as HTMLElement;
    while (element != null) {
      element = element.parentElement;
      if (element?.tagName == 'TABLE') tableElement = element as HTMLTableElement;
    }

    if (!tableElement) return this.hide();

    this.tippy.setProps({
      getReferenceClientRect: () => {
        return (tableElement as HTMLTableElement).getBoundingClientRect();
      }
    });

    this.show();
  }

  show() {
    this.tippy.show();
  }

  hide() {
    this.tippy.hide();
  }

  destroy() {
    this.tippy.destroy();
    this.element.removeEventListener('mousedown', this.mousedownHandler);
    this.view.dom.removeEventListener('dragstart', this.dragstartHandler);
    this.editor.off('focus', this.focusHandler);
    this.editor.off('blur', this.blurHandler);
  }
}

export let TableMenuPluginKey = new PluginKey('menuBubble');

export let TableMenuPlugin = (options: TableMenuPluginProps) => {
  return new Plugin({
    key: TableMenuPluginKey,
    view: view => new TableMenuView({ view, ...options })
  });
};
