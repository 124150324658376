import React, { useEffect, useRef } from 'react';
import { TableMenuPlugin, TableMenuPluginKey, TableMenuPluginProps } from './plugin';

export type TableMenuProps = Omit<TableMenuPluginProps, 'element'> & {
  className?: string;
};

export let TableMenuProvider: React.FC<TableMenuProps> = props => {
  let element = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let { editor, tippyOptions } = props;

    editor.registerPlugin(
      TableMenuPlugin({
        editor,
        element: element.current as HTMLElement,
        tippyOptions
      })
    );

    return () => {
      editor.unregisterPlugin(TableMenuPluginKey);
    };
  }, []);

  return (
    <div ref={element} className={props.className} style={{ visibility: 'hidden' }}>
      {props.children}
    </div>
  );
};
