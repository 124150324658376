import { Editor, BubbleMenu } from '@tiptap/react';
import { styled } from 'buttered';
import clsx from 'clsx';
import { Menu, Spacer, VerticalCenter } from '@vapor/ui';
import { useState, useMemo } from 'react';
import BoldIcon from 'remixicon-react/BoldIcon';
import ItalicIcon from 'remixicon-react/ItalicIcon';
import LinkIcon from 'remixicon-react/LinkIcon';
import StrikethroughIcon from 'remixicon-react/StrikethroughIcon';
import CodeIcon from 'remixicon-react/CodeSLineIcon';
import UnderlineIcon from 'remixicon-react/UnderlineIcon';
import { FiChevronDown, FiChevronLeft } from 'react-icons/fi';
import { Button } from '../components/button';
import { isUrl } from '../lib/isUrl';

let InlineToolbarWrapper = styled('nav')`
  padding: 6px 3px 6px 6px;
  border-radius: var(--vapor-radius-big);
  background: var(--vapor-foreground);
  position: relative;
`;

let InlineToolbarInner = styled('div')`
  display: flex;
`;

let InlineToolbarLinkWrapper = styled('div')`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  border: var(--vapor-border);
  background: var(--vapor-background);
  overflow: hidden;
  border-radius: var(--vapor-radius-medium);
  transition: all 0.3s;
  display: flex;

  .back {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 6px 0px 6px;
    margin: 3px 0px 3px 3px;
    background: var(--vapor-accent-2);
    border-radius: 5px;
    border: none;
    transition: all 0.3s;

    &:hover,
    &:focus {
      background: var(--vapor-accent-4);
    }

    svg {
      height: 14px;
      width: 14px;
    }
  }

  input {
    flex-grow: 1;
    height: 100%;
    padding: 0px 10px 0px 10px;
    background: var(--vapor-background);
    outline: none;
    border: none;
    font-size: 14px;
  }

  &.isHidden {
    opacity: 0;
    pointer-events: none;
  }
`;

let BlockFormatButton = styled('button')`
  height: 26px;
  padding: 0px 8px;
  color: var(--vapor-accent-5);
  outline: none;
  border: none;
  background: transparent;
  transition: all 0.3s;
  border-radius: var(--vapor-radius-medium);
  margin-right: 4px;
  font-size: 14px;
  font-weight: 500;
  display: flex;

  &:hover,
  &:focus {
    background: var(--vapor-accent-8);
    color: var(--vapor-background);
  }
`;

let blockFormats = [
  {
    id: 'p',
    name: 'Paragraph'
  },
  {
    id: 'h1',
    name: 'Heading 1'
  },
  {
    id: 'h2',
    name: 'Heading 2'
  },
  {
    id: 'h3',
    name: 'Heading 3'
  },
  {
    id: 'blockquote',
    name: 'Blockquote'
  }
];

export let InlineMenu = ({
  editorRef
}: {
  editorRef: React.RefObject<Editor | undefined>;
}) => {
  let [linkValue, setLinkValue] = useState('');
  let [linkMenuActive, setLinkMenuActive] = useState(false);
  let [selectedFormatId, setSelectedFormatId] = useState<string>();

  let editor = editorRef.current;

  let getId = () => {
    let editor = editorRef.current;
    if (!editor) return undefined;
    if (
      editor.isActive('bulletList') ||
      editor.isActive('orderedList') ||
      editor.isActive('taskList') ||
      editor.isActive('table') ||
      editor.isActive('note')
    )
      return undefined;

    if (editor.isActive('heading', { level: 1 })) return 'h1';
    if (editor.isActive('heading', { level: 2 })) return 'h2';
    if (editor.isActive('heading', { level: 3 })) return 'h3';
    if (editor.isActive('blockquote')) return 'blockquote';
    if (editor.isActive('paragraph')) return 'p';
    return undefined;
  };

  let setId = () => {
    let newId = getId();
    setSelectedFormatId(newId);
  };

  let selectedFormat = useMemo(
    () => (selectedFormatId ? blockFormats.find(f => f.id == selectedFormatId) : undefined),
    [selectedFormatId]
  );

  if (!editor) return null;

  return (
    <BubbleMenu
      className="floating-menu"
      tippyOptions={{
        duration: 300,
        onTrigger: () => setId(),
        onAfterUpdate: () => setId(),
        onHidden: () => setLinkMenuActive(false)
      }}
      editor={editor}
    >
      <InlineToolbarWrapper>
        <InlineToolbarInner>
          {selectedFormat && (
            <Menu
              label="Demo Menu"
              action={value => {
                if (!editor) return;
                let chain = editor.chain().focus();

                if (value.startsWith('h')) {
                  chain
                    .lift('blockquote')
                    .setNode('heading', { level: Number.parseInt(value.substr(1)) });
                }

                if (value == 'blockquote') {
                  chain.setNode('paragraph').wrapIn('blockquote');
                }

                if (value == 'p') {
                  chain.lift('blockquote').setNode('paragraph');
                }

                chain.run();
              }}
              items={blockFormats.map(f => ({
                id: f.id,
                component: <span style={{ fontWeight: 500 }}>{f.name}</span>,
                type: 'item'
              }))}
            >
              {({ attrs, ref }) => (
                <BlockFormatButton {...attrs} ref={ref}>
                  <VerticalCenter>
                    <span>{selectedFormat?.name}</span>
                  </VerticalCenter>

                  <Spacer width={5} />

                  <VerticalCenter>
                    <FiChevronDown size={14} />
                  </VerticalCenter>
                </BlockFormatButton>
              )}
            </Menu>
          )}

          <Button
            isActive={editor.isActive('bold')}
            onClick={() => editor && editor.chain().focus().toggleBold().run()}
            icon={<BoldIcon size={18} />}
            label="Bold"
          />

          <Button
            isActive={editor.isActive('italic')}
            onClick={() => editor && editor.chain().focus().toggleItalic().run()}
            icon={<ItalicIcon size={18} />}
            label="Italic"
          />

          <Button
            isActive={editor.isActive('underline')}
            onClick={() => editor && editor.chain().focus().toggleUnderline().run()}
            icon={<UnderlineIcon size={18} />}
            label="Underline"
          />

          <Button
            isActive={editor.isActive('strike')}
            onClick={() => editor && editor.chain().focus().toggleStrike().run()}
            icon={<StrikethroughIcon size={18} />}
            label="Strike Trough"
          />

          <Button
            isActive={editor.isActive('code')}
            onClick={() => editor && editor.chain().focus().toggleCode().run()}
            icon={<CodeIcon size={18} />}
            label="Code"
          />

          <Button
            isActive={editor.isActive('link')}
            onClick={() => {
              if (!editor) return;

              if (editor.isActive('link')) {
                editor.chain().focus().unsetLink().run();
              } else {
                setLinkMenuActive(true);
              }
            }}
            icon={<LinkIcon size={18} />}
            label="Link"
          />
        </InlineToolbarInner>

        <InlineToolbarLinkWrapper className={clsx({ isHidden: !linkMenuActive })}>
          <button
            className="back"
            role="button"
            tabIndex={0}
            onClick={() => {
              setLinkMenuActive(false);
            }}
          >
            <FiChevronLeft />
          </button>

          <input
            value={linkValue}
            onChange={e => setLinkValue(e.target.value)}
            placeholder="Enter a link"
            autoFocus={linkMenuActive}
            onKeyDown={e => {
              if (!editor) return;

              if (e.key == 'Enter') {
                e.preventDefault();
                e.stopPropagation();

                if (!isUrl(linkValue)) return;

                editor.chain().focus().setLink({ href: linkValue }).run();
                setLinkMenuActive(false);

                setTimeout(() => {
                  setLinkValue('');
                }, 300);
              }
            }}
          />
        </InlineToolbarLinkWrapper>
      </InlineToolbarWrapper>
    </BubbleMenu>
  );
};
