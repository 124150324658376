export let isUrl = (url: string) => {
  try {
    let u = new URL(url);
    return (
      (u.protocol == 'http:' || u.protocol == 'https:') && u.hostname.split('.').length >= 2
    );
  } catch (e) {}

  return false;
};
