import { useMemo } from 'react'
import { NodeViewContent, NodeViewWrapper, NodeViewProps } from '@tiptap/react'

export let CodeBlock = (props: NodeViewProps) => {
  let languages = useMemo(() => props.extension.options.lowlight.listLanguages() as string[], []);
  let selectedLanguage = props.node.attrs.language || 'auto';

  return (
    <NodeViewWrapper className="code-block">
      <select contentEditable={false} value={selectedLanguage} onChange={(e) => {
        props.updateAttributes({ language: e.target.value == 'auto' ? null : e.target.value });
      }}>
        <option value="auto">
          auto
        </option>
        <option disabled>
          —
        </option>
        {languages.map(l => (
          <option value={l} key={l}>
            {l}
          </option>
        ))}
      </select>

      <pre>
        <NodeViewContent as="code" />
      </pre>
    </NodeViewWrapper>
  )
}