import { EditorContent } from '@tiptap/react';
import useDelayed from 'use-delayed';
import clsx from 'clsx';
import { useMemo } from 'react';
import { useEditor } from './useEditor';
import { EditorWrapper } from './editorWrapper';
import { InlineMenu } from './menus/inline';
import { PlusMenu } from './menus/plus';
import { EmbedModal } from './menus/blockMenu/embedModal';
import { TableMenu } from './menus/tableMenu';

export let Editor = ({
  onChange,
  content
}: {
  onChange: (html: string) => void;
  content: string;
}) => {
  let { editor, editorRef } = useEditor({
    onChange,
    content
  });

  let disableMenu = useMemo(() => {
    if (!editor) return false;

    let content = (editor.state.selection.content().content as any).content;
    if (!content) return false;

    let node = content.find(
      (n: any) =>
        n.type.name == 'codeBlock' || n.type.name == 'horizontalRule' || n.type.name == 'embed'
    );
    if (!node) return false;

    if (node.type.name == 'embed') return true;
    if (node.type.name == 'codeBlock') return true;
    if (node.type.name == 'horizontalRule' && content.length == 1) return true;

    return false;
  }, [editor?.state.selection]);

  let delayedDisableMenu = useDelayed(disableMenu, 300, [true]);

  return (
    <EditorWrapper className={clsx({ disableMenu: delayedDisableMenu })}>
      <EditorContent editor={editor} className="editor" />

      <InlineMenu editorRef={editorRef} />

      <TableMenu editorRef={editorRef} />

      <PlusMenu editorRef={editorRef} />

      <EmbedModal editorRef={editorRef} />
    </EditorWrapper>
  );
};
