import { styled } from 'buttered';
import clsx from 'clsx';

let ButtonWrapper = styled('button')`
  height: 26px;
  width: 26px;
  color: var(--vapor-accent-5);
  outline: none;
  border: none;
  background: transparent;
  transition: all 0.3s;
  border-radius: var(--vapor-radius-medium);
  margin-right: 4px;

  &.isActive,
  &:hover,
  &:focus {
    color: var(--vapor-background);
  }

  &:hover,
  &:focus {
    background: var(--vapor-accent-8);
  }

  &.big {
    height: 32px;
    width: 32px;
  }
`;

let ButtonIcon = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export let Button = ({
  icon,
  label,
  isActive,
  size,
  onClick
}: {
  icon: React.ReactElement;
  label: string;
  isActive?: boolean;
  size?: 'big';
  onClick: () => void;
}) => {
  return (
    <ButtonWrapper
      onClick={onClick}
      className={clsx(size, { isActive })}
      aria-label={label}
      title={label}
    >
      <ButtonIcon>{icon}</ButtonIcon>
    </ButtonWrapper>
  );
};
