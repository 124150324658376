import { Node, mergeAttributes } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';
import { EmbedComponent } from './component';

export let EmbedNode = Node.create({
  name: 'embed',
  group: 'block',
  // content: 'inline*',
  atom: true,

  addAttributes() {
    return {
      type: {},
      src: {}
    };
  },

  parseHTML() {
    return [
      {
        tag: 'iframe'
      }
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return ['iframe', mergeAttributes(HTMLAttributes)];
  },
  addNodeView() {
    return ReactNodeViewRenderer(EmbedComponent);
  },
  addCommands() {
    return {
      setEmbed:
        options =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: options
          });
        }
    };
  }
});
