export let createObserver = <T>(): Observer<T> => {
  let listener: ((d: T) => unknown) | undefined;

  return {
    fire: (d: T) => {
      if (listener) listener(d);
    },
    listener: (l: (d: T) => unknown) => {
      listener = l;
      return () => {
        if (listener == l) listener = undefined;
      }
    }
  }
}

export interface Observer<T> {
  fire: (d: T) => void,
  listener: (listener: (d: T) => unknown) => () => void;
}
