import React from 'react'
import { NodeViewContent, NodeViewWrapper, NodeViewProps } from '@tiptap/react'

let types = {
  note: {
    name: 'Note',
    color: '#3867d6',
    textColor: '#ffffff'
  },
  alert: {
    name: 'Alert',
    color: '#eb3b5a',
    textColor: '#ffffff'
  },
  warning: {
    name: 'Warning',
    color: '#f7b731',
    textColor: '#ffffff'
  }
}

export let NoteComponent = (props: NodeViewProps) => {
  let setType = (type: string) => {
    props.updateAttributes({
      type
    })
  }

  let selectedTypeId: 'note' | 'alert' | 'warning' = props.node.attrs.type || 'note';
  let selectedType = types[selectedTypeId];

  return (
    <NodeViewWrapper className="note" style={{
      background: selectedType.color,
      color: selectedType.textColor
    }}>
      <select contentEditable={false} value={selectedTypeId} onChange={(e) => setType(e.target.value)}>
        {Object.entries(types).map(([key, value]) => (
          <option value={key} key={key}>
            {value.name}
          </option>
        ))}
      </select>

      <NodeViewContent as="p" />
    </NodeViewWrapper>
  )
}