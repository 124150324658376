import { useMemo } from 'react';
import { Editor } from '@tiptap/react';
import { Range } from '@tiptap/core';
import tippy, { Instance, Props } from 'tippy.js';
import { SuggestionKeyDownProps, SuggestionProps } from '@tiptap/suggestion';

import CodeIcon from 'remixicon-react/CodeSLineIcon';
import H1Icon from 'remixicon-react/H1Icon';
import H2Icon from 'remixicon-react/H2Icon';
import H3Icon from 'remixicon-react/H3Icon';
import ParagraphIcon from 'remixicon-react/ParagraphIcon';
import QuoteIcon from 'remixicon-react/DoubleQuotesLIcon';

import ListOrderedIcon from 'remixicon-react/ListOrderedIcon';
import ListUnorderedIcon from 'remixicon-react/ListUnorderedIcon';
import AlertIcon from 'remixicon-react/AlertLineIcon';
import SeparatorIcon from 'remixicon-react/SeparatorIcon';

import ImageIcon from 'remixicon-react/Image2LineIcon';
import WindowIcon from 'remixicon-react/WindowLineIcon';
import TableIcon from 'remixicon-react/Table2Icon';

import { ReactRenderer } from '../../lib/reactRenderer';
import { BlockMenu } from './component';
import { Commands } from './commands';
import { createObserver } from '../../lib/observer';
import { useEmbedModalOpen } from './state';

export let useBlockMenuExtension = (editorRef: React.RefObject<Editor | undefined>) => {
  let [, setEmbedModalOpen] = useEmbedModalOpen();

  let extension = useMemo(
    () =>
      Commands.configure({
        suggestion: {
          items: (query: string) => {
            let items = [
              [
                {
                  icon: <ParagraphIcon size={18} />,
                  title: 'Paragraph',
                  alt: 'text normal unstyled',
                  command: ({ editor, range }: { editor: Editor; range: Range }) => {
                    editor
                      .chain()
                      .focus()
                      .deleteRange(range)
                      .setParagraph()
                      .lift('blockquote')
                      .run();
                  }
                },
                {
                  icon: <H1Icon size={18} />,
                  title: 'Heading 1',
                  command: ({ editor, range }: { editor: Editor; range: Range }) => {
                    editor
                      .chain()
                      .focus()
                      .deleteRange(range)
                      .lift('blockquote')
                      .setNode('heading', { level: 1 })
                      .run();
                  }
                },
                {
                  icon: <H2Icon size={18} />,
                  title: 'Heading 2',
                  command: ({ editor, range }: { editor: Editor; range: Range }) => {
                    editor
                      .chain()
                      .focus()
                      .deleteRange(range)
                      .lift('blockquote')
                      .setNode('heading', { level: 2 })
                      .run();
                  }
                },
                {
                  icon: <H3Icon size={18} />,
                  title: 'Heading 3',
                  command: ({ editor, range }: { editor: Editor; range: Range }) => {
                    editor
                      .chain()
                      .focus()
                      .deleteRange(range)
                      .lift('blockquote')
                      .setNode('heading', { level: 3 })
                      .run();
                  }
                },
                {
                  icon: <QuoteIcon size={18} />,
                  title: 'Quote',
                  command: ({ editor, range }: { editor: Editor; range: Range }) => {
                    editor
                      .chain()
                      .focus()
                      .deleteRange(range)
                      .setNode('paragraph')
                      .toggleBlockquote()
                      .run();
                  }
                }
              ],
              [
                {
                  icon: <AlertIcon size={18} />,
                  title: 'Note or Alert',
                  alt: 'warning hint tip',
                  command: ({ editor, range }: { editor: Editor; range: Range }) => {
                    editor.chain().deleteRange(range).toggleNote().focus().run();
                  }
                },
                {
                  icon: <SeparatorIcon size={18} />,
                  title: 'Separator',
                  alt: 'hr line',
                  command: ({ editor, range }: { editor: Editor; range: Range }) => {
                    editor.chain().focus().deleteRange(range).setHorizontalRule().run();
                  }
                },
                {
                  icon: <ListUnorderedIcon size={18} />,
                  title: 'Bulleted List',
                  alt: 'unordered',
                  command: ({ editor, range }: { editor: Editor; range: Range }) => {
                    editor.chain().focus().deleteRange(range).toggleBulletList().run();
                  }
                },
                {
                  icon: <ListOrderedIcon size={18} />,
                  title: 'Ordered List',
                  alt: 'numbered',
                  command: ({ editor, range }: { editor: Editor; range: Range }) => {
                    editor.chain().focus().deleteRange(range).toggleOrderedList().run();
                  }
                }
              ],
              [
                {
                  icon: <ImageIcon size={18} />,
                  title: 'Image',
                  alt: 'picture photo',
                  command: ({ editor, range }: { editor: Editor; range: Range }) => {}
                },
                {
                  icon: <WindowIcon size={18} />,
                  title: 'Embed',
                  alt: 'iframe',
                  command: ({ editor, range }: { editor: Editor; range: Range }) => {
                    setEmbedModalOpen(range);
                  }
                },
                {
                  icon: <CodeIcon size={18} />,
                  title: 'Code Block',
                  command: ({ editor, range }: { editor: Editor; range: Range }) => {
                    editor.chain().deleteRange(range).toggleCodeBlock().focus().run();
                  }
                },
                {
                  icon: <TableIcon size={18} />,
                  title: 'Table',
                  command: ({ editor, range }: { editor: Editor; range: Range }) => {
                    editor
                      .chain()
                      .deleteRange(range)
                      .insertTable({ rows: 3, cols: 3, withHeaderRow: true })
                      .focus()
                      .run();
                  }
                }
              ]
            ];

            let normalizedQuery = query.trim().substr(1).toLowerCase();
            if (normalizedQuery.length == 0) return items;

            return [
              items
                .map(i =>
                  i.filter(
                    i =>
                      i.title.toLowerCase().includes(query) ||
                      (i.alt && i.alt.toLowerCase().includes(query))
                  )
                )
                .flat()
            ] as any;
          },
          render: () => {
            let component: ReturnType<typeof ReactRenderer>;
            let popup: Instance<Props>[];
            let keyboardObserver = createObserver<SuggestionKeyDownProps>();

            return {
              onStart: (props: SuggestionProps) => {
                if (editorRef.current && editorRef.current.can().addColumnBefore()) return;

                component = ReactRenderer(BlockMenu, {
                  props: { ...props, editor: editorRef, keyboardObserver, created: true }
                });

                popup = tippy('body', {
                  duration: 400,
                  getReferenceClientRect: props.clientRect,
                  appendTo: () => document.body,
                  content: component.element,
                  showOnCreate: true,
                  interactive: true,
                  trigger: 'manual',
                  placement: 'bottom-start'
                });
              },
              onUpdate(props: SuggestionProps) {
                component.setProps({ ...props, editor: editorRef, keyboardObserver });

                popup[0].setProps({
                  getReferenceClientRect: props.clientRect
                });
              },
              onKeyDown(props: SuggestionKeyDownProps) {
                keyboardObserver.fire(props);

                if (
                  props.event.key == 'ArrowDown' ||
                  props.event.key == 'ArrowUp' ||
                  props.event.key == 'ArrowRight' ||
                  props.event.key == 'ArrowLeft' ||
                  props.event.key == 'Enter'
                )
                  return true;

                return false;
              },
              onExit() {
                if (popup) popup[0].destroy();
                if (component) component.dispose();
              }
            };
          }
        }
      }),
    [editorRef]
  );

  return extension;
};
