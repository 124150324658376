import { VaporProvider } from '@vapor/ui';
import { styled } from 'buttered';
import React from 'react';
import { useState } from 'react';
import ReactDOM from 'react-dom';
import { Editor } from './editor';
import './index.css';
import './styles/reset.css';
import './styles/variables.css';

let Grid = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1.4fr;
`;

let Wrapper = styled('div')`
  padding: 100px;
  height: 100vh;
  overflow: auto;
`;

let Inner = styled('div')`
  max-width: 720px;
  margin: 0px auto;
`;

let App = () => {
  let [html, setHTMl] = useState('');

  return (
    <Grid>
      <Wrapper style={{ background: '#efefef' }}>
        <Inner>
          <div>{html}</div>
        </Inner>
      </Wrapper>
      <Wrapper>
        <Inner>
          <Editor content="<p>Hello world</p>" onChange={html => setHTMl(html)} />
        </Inner>
      </Wrapper>
    </Grid>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <VaporProvider>
      <App />
    </VaporProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
