import { Editor } from '@tiptap/react';
import { styled } from 'buttered';
import InsertRowBottomIcon from 'remixicon-react/InsertRowBottomIcon';
import InsertRowTopIcon from 'remixicon-react/InsertRowTopIcon';
import InsertColumnLeftIcon from 'remixicon-react/InsertColumnLeftIcon';
import InsertColumnRightIcon from 'remixicon-react/InsertColumnRightIcon';
import DeleteColumnIcon from 'remixicon-react/DeleteColumnIcon';
import DeleteRowIcon from 'remixicon-react/DeleteRowIcon';
import HeadingIcon from 'remixicon-react/HeadingIcon';
import { Button } from '../../components/button';
import { TableMenuProvider } from './component';

let Wrapper = styled('div')`
  padding: 10px 7px 10px 12px;
  border-radius: var(--vapor-radius-big);
  background: var(--vapor-foreground);
`;

export let TableMenu = ({ editorRef }: { editorRef: React.RefObject<Editor | undefined> }) => {
  if (!editorRef.current) return null;
  let editor = editorRef.current;

  return (
    <TableMenuProvider editor={editor}>
      <Wrapper>
        <Button
          onClick={() => editor && editor.chain().focus().addRowAfter().run()}
          icon={<InsertRowBottomIcon size={18} />}
          label="Insert Row After"
          size="big"
        />

        <Button
          onClick={() => editor && editor.chain().focus().addRowBefore().run()}
          icon={<InsertRowTopIcon size={18} />}
          label="Insert Row Before"
          size="big"
        />

        <Button
          onClick={() => editor && editor.chain().focus().addColumnBefore().run()}
          icon={<InsertColumnLeftIcon size={18} />}
          label="Insert Column Left"
          size="big"
        />

        <Button
          onClick={() => editor && editor.chain().focus().addColumnAfter().run()}
          icon={<InsertColumnRightIcon size={18} />}
          label="Insert Column Right"
          size="big"
        />

        <Button
          onClick={() => editor && editor.chain().focus().deleteColumn().run()}
          icon={<DeleteColumnIcon size={18} />}
          label="Delete Column"
          size="big"
        />

        <Button
          onClick={() => editor && editor.chain().focus().deleteRow().run()}
          icon={<DeleteRowIcon size={18} />}
          label="Delete Row"
          size="big"
        />

        <Button
          onClick={() => editor && editor.chain().focus().toggleHeaderRow().run()}
          icon={<HeadingIcon size={18} />}
          label="Heading Row"
          size="big"
        />
      </Wrapper>
    </TableMenuProvider>
  );
};
