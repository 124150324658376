import React, { useEffect, useState } from 'react';
import { createRef } from 'react';
import ReactDOM from 'react-dom';

export let ReactRenderer = (
  Component: (props: any) => React.ReactElement,
  opts: {
    props: any;
  }
) => {
  let element = document.createElement('div');
  let listeners: ((props: any) => void)[] = [];

  let setProps = (props: any) => {
    listeners.forEach(l => l(props));
  };

  let Wrapper = () => {
    let [internalProps, setInternalProps] = useState(() => opts.props);

    useEffect(() => {
      let listener = (p: any) => setInternalProps(p);

      listeners.push(listener);
      return () => {
        listeners = listeners.filter(l => l != listener);
      };
    }, []);

    return <Component {...internalProps} />;
  };

  ReactDOM.render(<Wrapper />, element);

  return {
    element,
    setProps,
    dispose: () => {
      element.remove();
    }
  };
};
