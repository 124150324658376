import { styled } from 'buttered';

export let EditorWrapper = styled('div')`
  &.disableMenu {
    .floating-menu {
      display: none;
    }
  }

  .ProseMirror {
    outline: none;
    line-height: 1.3;

    > * + * {
      margin-top: 0.75em;
    }

    ul,
    ol {
      padding: 0.75em;
      margin: 0px 1rem;
    }

    li {
      padding: 3px 0px;
    }

    ul[data-type='taskList'] {
      padding: 0.75em 0px;

      li {
        display: flex;
        align-items: center;

        > label {
          flex: 0 0 auto;
          margin-right: 0.5rem;
        }

        div {
          margin-left: 5px;
        }
      }

      input[type='checkbox'] {
        cursor: pointer;
      }
    }

    ul:not([data-type='taskList']) {
      li {
        &:before {
          content: '–';
          display: inline-block;
          position: absolute;
          margin-left: -20px;
        }
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      line-height: 1.2;
    }

    code {
      background-color: #efefef;
      color: #222;
      padding: 0.1em 4px;
      border-radius: 4px;
      font-size: 0.9em;
      display: inline-block;
    }

    .code-block {
      position: relative;

      select {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        border: solid transparent 1px;
        background: #555;
        border-radius: 5px;
        padding: 2px 5px;
        outline: none;
        font-size: 10px;
        opacity: 0;
        transition: all 0.3s;
        color: white;

        &:hover,
        &:focus {
          opacity: 1;
          color: white;
          background: black;
        }
      }

      &:hover,
      &:focus-within {
        select {
          opacity: 0.6;
        }
      }
    }

    .note {
      position: relative;
      border-radius: 7px;
      padding: 12px 14px;
      font-size: 14px;
      line-height: 1.4;

      select {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        border: solid transparent 1px;
        background: #efefef;
        border-radius: 5px;
        padding: 2px 5px;
        outline: none;
        font-size: 10px;
        transition: all 0.3s;
        color: black;
        opacity: 0;

        &:hover,
        &:focus {
          opacity: 1;
          color: black;
          border: solid #efefef 1px;
          background: white;
        }
      }

      &:hover,
      &:focus-within {
        select {
          opacity: 0.6;
        }
      }
    }

    pre {
      background: #efefef;
      color: black;
      font-family: 'JetBrainsMono', monospace;
      padding: 16px 18px;
      border-radius: 8px;
      line-height: 1.3em;

      code {
        color: inherit;
        padding: 0;
        background: none;
        font-size: 0.8rem;
      }

      .hljs-comment,
      .hljs-quote {
        color: #616161;
      }

      .hljs-variable,
      .hljs-template-variable,
      .hljs-attribute,
      .hljs-tag,
      .hljs-name,
      .hljs-regexp,
      .hljs-link,
      .hljs-name,
      .hljs-selector-id,
      .hljs-selector-class {
        color: #eb3b5a;
      }

      .hljs-number,
      .hljs-meta,
      .hljs-built_in,
      .hljs-builtin-name,
      .hljs-literal,
      .hljs-type,
      .hljs-params {
        color: #fa8231;
      }

      .hljs-string,
      .hljs-symbol,
      .hljs-bullet {
        color: #10ac84;
      }

      .hljs-title,
      .hljs-section {
        color: #f7b731;
      }

      .hljs-keyword,
      .hljs-selector-tag {
        color: #3867d6;
      }

      .hljs-emphasis {
        font-style: italic;
      }

      .hljs-strong {
        font-weight: 700;
      }
    }

    img {
      max-width: 100%;
      height: auto;
    }

    blockquote {
      border-left: 3px solid #efefef;
      margin: 0.75em 0px;
      padding: 4px 0px 4px 1rem;
    }

    hr {
      border: none;
      border-top: 2px solid #efefef;
      margin: 1rem 0px;
    }

    table {
      border-collapse: collapse;
      table-layout: fixed;
      width: 100%;
      margin: 15px 0;
      overflow: hidden;

      td,
      th {
        min-width: 1em;
        border: 1px solid #ddd;
        padding: 3px 5px;
        vertical-align: top;
        box-sizing: border-box;
        position: relative;

        > * {
          margin-bottom: 0;
        }
      }

      th {
        font-weight: 600;
        text-align: left;
        background-color: #f6f6f6;
      }

      .selectedCell:after {
        z-index: 2;
        position: absolute;
        content: '';
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba(230, 230, 230, 0.5);
        pointer-events: none;
      }
    }
  }

  .tableWrapper {
    overflow-x: auto;
  }

  .resize-cursor {
    cursor: ew-resize;
    cursor: col-resize;
  }
`;
