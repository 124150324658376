import { useEffect, useRef } from 'react';
import { useEditor as useTiptap, ReactNodeViewRenderer, Editor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Typography from '@tiptap/extension-typography';
import Underline from '@tiptap/extension-underline';
import Link from '@tiptap/extension-link';
import Heading from '@tiptap/extension-heading';
import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight';
import Table from '@tiptap/extension-table';
import TableRow from '@tiptap/extension-table-row';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import Focus from '@tiptap/extension-focus';

import lowlight from 'lowlight';

import { CodeBlock } from './nodes/codeBlock';
import { NoteNode } from './nodes/note';
import { useBlockMenuExtension } from './menus/blockMenu/useBlockMenuExtension';
import { EmbedNode } from './nodes/embed';

export let useEditor = ({
  onChange,
  content
}: {
  onChange: (value: string) => void;
  content: string;
}) => {
  let editorRef = useRef<Editor>();

  let BlockMenuExtension = useBlockMenuExtension(editorRef);

  let editor = useTiptap({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Table,
      TableRow,
      TableHeader,
      TableCell,
      Heading.configure({
        levels: [1, 2, 3]
      }),
      CodeBlockLowlight.extend({
        addNodeView() {
          return ReactNodeViewRenderer(CodeBlock);
        }
      }).configure({ lowlight }),
      NoteNode,
      BlockMenuExtension,
      EmbedNode,
      Focus.configure({
        className: 'has-focus',
        mode: 'all'
      }),
      Typography
    ],
    content,
    onUpdate: ({ editor }) => {
      onChange(editor.getHTML());
    }
  });

  editorRef.current = editor || undefined;

  useEffect(() => {
    if (editor && content) editor.commands.setContent(content);
  }, [content, editor]);

  return { editor, editorRef };
};
