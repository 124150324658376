import { Editor } from '@tiptap/react';
import { Modal, Input, VerticalCenter, Spacer, Button } from '@vapor/ui';
import { styled } from 'buttered';
import { useMemo, useRef, useState, useEffect } from 'react';
import { FiArrowRight } from 'react-icons/fi';
import { providers } from '../../nodes/embed/providers';
import { useEmbedModalOpen } from './state';

let Preview = styled('div')`
  height: 400px;
  background: var(--vapor-accent-2);
  border-radius: var(--vapor-radius-big);
  margin-top: 16px;
  overflow: hidden;
  border: var(--vapor-border);
`;

let FrameWrapper = styled('div')`
  height: 100%;
  display: grid;
  grid-template-rows: 30px 1fr;
`;

let ProviderDetails = styled('aside')`
  display: flex;
  padding: 7px 12px 6px 12px;
  border-bottom: var(--vapor-border);

  img {
    height: 16px;
    margin-right: 10px;
  }

  p {
    font-size: 14px;
  }
`;

let Empty = styled('div')`
  text-align: center;
  height: 100%;

  h1 {
    font-weight: 600;
    font-size: 22px;
  }

  p {
    font-weight: 500;
    font-size: 18px;
  }
`;

let InputGrid = styled('div')`
  display: grid;
  grid-template-columns: 1fr 36px;
  gap: 10px;
`;

export let EmbedModal = ({
  editorRef
}: {
  editorRef: React.RefObject<Editor | undefined>;
}) => {
  let [embedModalOpen, setEmbedModalOpen] = useEmbedModalOpen();
  let [embedUrl, setEmbedUrl] = useState('');
  let inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    if (embedModalOpen) {
      setTimeout(() => {
        if (inputRef.current) inputRef.current.focus();
      }, 300);

      setEmbedUrl('');
    }
  }, [embedModalOpen]);

  let match = useMemo(() => {
    let regexp!: RegExp;
    let provider = providers.find(
      p =>
        !!p.matcher.find(m => {
          let res = embedUrl.match(m);
          if (!res) return false;

          regexp = m;
          return true;
        })
    );
    if (!provider) return undefined;

    return {
      provider,
      parsed: provider.parse(embedUrl, regexp)
    };
  }, [embedUrl]);

  let insert = () => {
    if (!match || !editorRef.current) return;

    editorRef.current
      .chain()
      .deleteRange(embedModalOpen)
      .setEmbed({
        src: match.parsed.url,
        type: match.provider.type
      })
      .focus()
      .run();

    setEmbedModalOpen(false);
  };

  return (
    <Modal.Wrapper
      isOpen={!!embedModalOpen}
      onClose={() => setEmbedModalOpen(false)}
      label="Create Embed Block"
      width={650}
    >
      <Modal.Header>Create Embed Block</Modal.Header>
      <Modal.Content>
        <InputGrid>
          <Input
            ref={inputRef}
            placeholder="Paste a link"
            label="Embed Link"
            hideLabel
            value={embedUrl}
            onChange={e => setEmbedUrl(e.target.value)}
            onKeyDown={e => {
              if (e.key == 'Enter' || e.key == 'Space') insert();
            }}
          />

          <Button
            aria-label="Insert Embed Block"
            icon={<FiArrowRight />}
            variant="primary"
            disabled={!match}
            onClick={insert}
          />
        </InputGrid>

        <Preview>
          {match && (
            <FrameWrapper>
              <ProviderDetails>
                <img src={match.provider.logo} alt={`${match.provider.name} Logo`} />
                <p>{match.provider.name || ''}</p>
              </ProviderDetails>

              <iframe
                sandbox="allow-same-origin allow-scripts allow-forms"
                allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                src={match.parsed.url}
                frameBorder={0}
                style={{ width: '100%', height: '100%' }}
              />
            </FrameWrapper>
          )}

          {!match && embedUrl.length > 5 && (
            <Empty>
              <VerticalCenter>
                <h1>Invalid embed url.</h1>
                <Spacer height={10} />
                <p>
                  Make sure to enter a valid embed url
                  <br /> from one of our supported providers.
                </p>
              </VerticalCenter>
            </Empty>
          )}

          {!match && embedUrl.length <= 5 && (
            <Empty>
              <VerticalCenter>
                <p>Paste a link from one of our supported providers.</p>
              </VerticalCenter>
            </Empty>
          )}
        </Preview>
      </Modal.Content>
    </Modal.Wrapper>
  );
};
