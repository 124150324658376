interface IProvider {
  matcher: RegExp[];
  type: string;
  name: string;
  logo: string;
  parse: (url: string, matcher: RegExp) => { id: string; url: string };
}

export let providers: IProvider[] = [
  {
    type: 'abstract',
    name: 'Abstract',
    logo: 'https://cdn.loopbound.com/assets/friends/abstract.png',
    matcher: [
      new RegExp('https?://share.(?:go)?abstract.com/(.*)$'),
      new RegExp('https?://app.(?:go)?abstract.com/(?:share|embed)/(.*)$')
    ],
    parse: (url, matcher) => {
      let id = url.match(matcher)![1];

      return {
        id,
        url: `https://app.abstract.com/embed/${id}`
      };
    }
  },
  {
    type: 'airtable',
    name: 'Airtable',
    logo: 'https://cdn.loopbound.com/assets/friends/airtable.png',
    matcher: [new RegExp('https://airtable.com/(?:embed/)?(shr.*)$')],
    parse: (url, matcher) => {
      let id = url.match(matcher)![1];

      return {
        id,
        url: `https://airtable.com/embed/${id}`
      };
    }
  },
  {
    type: 'cawemo',
    name: 'Cawemo',
    logo: 'https://cdn.loopbound.com/assets/friends/cawemo.png',
    matcher: [new RegExp('https?://cawemo.com/(?:share|embed)/(.*)$')],
    parse: (url, matcher) => {
      let id = url.match(matcher)![1];

      return {
        id,
        url: `https://cawemo.com/embed/${id}`
      };
    }
  },
  {
    type: 'clickup',
    name: 'Clickup',
    logo: 'https://cdn.loopbound.com/assets/friends/clickup.png',
    matcher: [new RegExp('^https?://share.clickup.com/[a-z]/[a-z]/(.*)/(.*)$')],
    parse: (url, matcher) => {
      return {
        id: 'unknown',
        url
      };
    }
  },
  {
    type: 'codepen',
    name: 'Codepen',
    logo: 'https://cdn.loopbound.com/assets/friends/codepen.png',
    matcher: [new RegExp('^https://codepen.io/(.*?)/(pen|embed)/(.*)$')],
    parse: (url, matcher) => {
      return {
        id: 'unknown',
        url: url.replace('pen', 'embed')
      };
    }
  },
  {
    type: 'codesandbox',
    name: 'Codesandbox',
    logo: 'https://cdn.loopbound.com/assets/friends/codesandbox.png',
    matcher: [new RegExp('^https://codesandbox.io/s/(.*)$')],
    parse: (url, matcher) => {
      return {
        id: 'unknown',
        url: url.replace('/s/', '/embed/')
      };
    }
  },
  {
    type: 'descript',
    name: 'Descript',
    logo: 'https://cdn.loopbound.com/assets/friends/descript.png',
    matcher: [new RegExp('https?://share.descript.com/view/(\\w+)$')],
    parse: (url, matcher) => {
      let id = url.match(matcher)![1];

      return {
        id,
        url: `https://share.descript.com/embed/${id}`
      };
    }
  },
  {
    type: 'figma',
    name: 'Figma',
    logo: 'https://cdn.loopbound.com/assets/friends/figma.png',
    matcher: [
      new RegExp('https://([w.-]+.)?figma.com/(file|proto)/([0-9a-zA-Z]{22,128})(?:/.*)?$')
    ],
    parse: (url, matcher) => {
      return {
        id: 'unknown',
        url: `https://www.figma.com/embed?embed_host=varld&url=${url}`
      };
    }
  },
  {
    type: 'framer',
    name: 'Framer',
    logo: 'https://cdn.loopbound.com/assets/friends/framer.png',
    matcher: [new RegExp('https://framer.com/(share|embed)/(.*)$')],
    parse: (url, matcher) => {
      return {
        id: 'unknown',
        url: url.replace('share', 'embed').replace('?editor=1', '')
      };
    }
  },
  {
    type: 'invision',
    name: 'Invision',
    logo: 'https://cdn.loopbound.com/assets/friends/invision.png',
    matcher: [new RegExp('^https://(invis.io/.*)|(projects.invisionapp.com/share/.*)$')],
    parse: (url, matcher) => {
      return {
        id: 'unknown',
        url: url
      };
    }
  },
  {
    type: 'loom',
    name: 'Loom',
    logo: 'https://cdn.loopbound.com/assets/friends/loom.png',
    matcher: [/^https:\/\/(www\.)?(use)?loom.com\/(embed|share)\/(.*)$/],
    parse: (url, matcher) => {
      return {
        id: 'unknown',
        url: url.replace('share', 'embed')
      };
    }
  },
  {
    type: 'lucidchart',
    name: 'Lucidchart',
    logo: 'https://cdn.loopbound.com/assets/friends/lucidchart.png',
    matcher: [
      /^https?:\/\/(www\.|app\.)?lucidchart.com\/documents\/(embeddedchart|view)\/(?<chartId>[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})(?:\/.*)?$/,
      /^https?:\/\/(www\.|app\.)?lucid.app\/lucidchart\/(?<chartId>[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})\/(embeddedchart|view)(?:\/.*)?$/
    ],
    parse: (url, matcher) => {
      let id = url.match(matcher)!.groups!.chartId;

      return {
        id,
        url: `https://lucidchart.com/documents/embeddedchart/${id}`
      };
    }
  },
  {
    type: 'marvel',
    name: 'Marvel',
    logo: 'https://cdn.loopbound.com/assets/friends/marvel.png',
    matcher: [new RegExp('^https://marvelapp.com/([A-Za-z0-9-]{6})/?$')],
    parse: (url, matcher) => {
      return {
        id: 'unknown',
        url: url
      };
    }
  },
  {
    type: 'mindmeister',
    name: 'Mindmeister',
    logo: 'https://cdn.loopbound.com/assets/friends/mindmeister.png',
    matcher: [
      new RegExp(
        '^https://([w.-]+.)?(mindmeister.com|mm.tt)(/maps/public_map_shell)?/(\\d+)(\\?t=.*)?(/.*)?$'
      )
    ],
    parse: (url, matcher) => {
      let matches = url.match(matcher)!;
      let id = matches[4] + (matches[5] || '') + (matches[6] || '');

      return {
        id,
        url: `https://www.mindmeister.com/maps/public_map_shell/${id}`
      };
    }
  },
  {
    type: 'miro',
    name: 'Miro',
    logo: 'https://cdn.loopbound.com/assets/friends/miro.png',
    matcher: [/^https:\/\/miro.com\/app\/board\/(.*)$/],
    parse: (url, matcher) => {
      let id = url.match(matcher)![1];

      return {
        id,
        url: `https://miro.com/app/embed/${id}`
      };
    }
  },
  {
    type: 'prezi',
    name: 'Prezi',
    logo: 'https://cdn.loopbound.com/assets/friends/prezi.png',
    matcher: [new RegExp('^https://prezi.com/view/(.*)$')],
    parse: (url, matcher) => {
      return {
        id: 'unknown',
        url: `${url.replace(/\/embed$/, '')}/embed`
      };
    }
  },
  {
    type: 'vimeo',
    name: 'Vimeo',
    logo: 'https://cdn.loopbound.com/assets/friends/vimeo.png',
    matcher: [
      /(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^/]*)\/videos\/|)(\d+)(?:|\/\?)/
    ],
    parse: (url, matcher) => {
      let id = url.match(matcher)![4];

      return {
        id,
        url: `https://player.vimeo.com/video/${id}?byline=0`
      };
    }
  },
  {
    type: 'youtube',
    name: 'Youtube',
    logo: 'https://cdn.loopbound.com/assets/friends/youtube.png',
    matcher: [
      /(?:https?:\/\/)?(?:www\.)?youtu\.?be(?:\.com)?\/?.*(?:watch|embed)?(?:.*v=|v\/|\/)([a-zA-Z0-9_-]{11})$/i
    ],
    parse: (url, matcher) => {
      let id = url.match(matcher)![1];

      return {
        id,
        url: `https://www.youtube.com/embed/${id}?modestbranding=1`
      };
    }
  }
];
