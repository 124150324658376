import { Node, mergeAttributes } from '@tiptap/core'
import { ReactNodeViewRenderer } from '@tiptap/react'
import { NoteComponent } from './component'

export let NoteNode = Node.create({
  name: 'note',
  group: 'block',
  content: 'inline*',

  addAttributes() {
    return {
      type: {
        default: 'note'
      }
    }
  },

  parseHTML() {
    return [
      {
        tag: 'div[data-note]',
      }
    ]
  },
  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes({ 'data-note': '' }, HTMLAttributes)]
  },
  addNodeView() {
    return ReactNodeViewRenderer(NoteComponent)
  },
  addCommands() {
    return {
      toggleNote: attributes => ({ commands }) => {
        return commands.toggleNode('note', 'paragraph', attributes)
      },
    }
  }
})