import React, { useState, useEffect } from 'react';
import { Editor, Range } from '@tiptap/react';
import { SuggestionKeyDownProps } from '@tiptap/suggestion';
import { styled } from 'buttered';
import { Observer } from '../../lib/observer';
import clsx from 'clsx';
import { useEmbedModalOpen } from './state';

let Wrapper = styled('nav')`
  border: var(--vapor-border);
  border-radius: var(--vapor-radius-big);
  box-shadow: var(--vapor-shadow-small);
  background: white;
`;

let Inner = styled('div')`
  display: grid;
`;

let Group = styled('section')`
  padding: 8px;

  &:not(:last-of-type) {
    border-right: var(--vapor-border);
  }
`;

let Button = styled('button')`
  width: 100%;
  padding: 7px 12px 5px 12px;
  display: flex;
  line-height: 19px;
  font-size: 14px;
  border-radius: var(--vapor-radius-medium);
  border: none;
  background: none;
  transition: all 0.3s;
  font-weight: 500;
  width: 100%;

  &:hover,
  &:focus,
  &.isSelected {
    background: var(--vapor-accent-2);
    color: var(--vapor-primary);

    .icon {
      color: var(--vapor-primary);
    }
  }
`;

let IconWrapper = styled('div')`
  margin-right: 8px;
  color: var(--vapor-accent-5);
`;

let TextWrapper = styled('div')``;

export interface IItem {
  title: string;
  icon: React.ReactElement;
  command: ({ editor, range }: { editor: Editor; range: Range }) => void;
}

export let BlockMenu = ({
  command,
  items,
  keyboardObserver,
  created
}: {
  items: IItem[][];
  command: (data: IItem) => void;
  keyboardObserver: Observer<SuggestionKeyDownProps>;
  created?: boolean;
}) => {
  let [selectedItem, setSelectedItem] = useState([0, 0]);
  let [selectedGroupId, selectedItemId] = selectedItem;
  let [embedModalOpen] = useEmbedModalOpen();

  let selectItem = (group: number, index: number) => {
    let item = items[group][index];

    try {
      if (item) command(item);
    } catch (err) {}
  };

  useEffect(
    () =>
      keyboardObserver.listener(({ event }) => {
        let rowsCount = items[selectedGroupId].length;
        let colsCount = items.length;

        if (event.key == 'ArrowDown') {
          let newRow = selectedItemId + 1;
          if (newRow >= rowsCount) newRow = 0;

          setSelectedItem([selectedGroupId, newRow]);
        } else if (event.key == 'ArrowUp') {
          let newRow = selectedItemId - 1;
          if (newRow < 0) newRow = rowsCount - 1;

          setSelectedItem([selectedGroupId, newRow]);
        } else if (event.key == 'ArrowRight') {
          let newCol = selectedGroupId + 1;
          if (newCol >= colsCount) newCol = 0;

          let newItemId = selectedItemId;
          if (newItemId >= items[newCol].length) {
            newItemId = items[newCol].length - 1;
          }

          setSelectedItem([newCol, newItemId]);
        } else if (event.key == 'ArrowLeft') {
          let newCol = selectedGroupId - 1;
          if (newCol < 0) newCol = colsCount - 1;

          let newItemId = selectedItemId;
          if (newItemId >= items[newCol].length) {
            newItemId = items[newCol].length - 1;
          }

          setSelectedItem([newCol, newItemId]);
        } else if (event.key == 'Enter') {
          selectItem(selectedGroupId, selectedItemId);
        }
      }),
    [keyboardObserver, items, selectedItemId, selectedGroupId]
  );

  useEffect(() => {
    if (created) setSelectedItem([0, 0]);
  }, [created]);

  useEffect(() => {
    if (
      !items[selectedGroupId][selectedItemId] &&
      selectedGroupId != 0 &&
      selectedItemId != 0
    ) {
      setSelectedItem([0, 0]);
    }
  }, [selectedGroupId, selectedItemId, items]);

  let empty = items.length == 1 && items[0].length == 0;

  if (embedModalOpen) return <div />;

  return (
    <Wrapper style={{ width: empty ? 230 : items.length * 160 }}>
      <Inner style={{ gridTemplateColumns: new Array(items.length).fill('1fr').join(' ') }}>
        {empty ? (
          <Group>
            <div style={{ padding: 10 }}>🕵️‍♀️ No commands found.</div>
          </Group>
        ) : (
          items.map((group, groupId) => (
            <Group key={groupId}>
              <ul>
                {group.map((item, itemId) => (
                  <li key={groupId + '/' + itemId}>
                    <Button
                      onClick={() => selectItem(groupId, itemId)}
                      className={clsx({
                        isSelected: selectedGroupId == groupId && selectedItemId == itemId
                      })}
                    >
                      <IconWrapper className="icon">{item.icon}</IconWrapper>
                      <TextWrapper>{item.title}</TextWrapper>
                    </Button>
                  </li>
                ))}
              </ul>
            </Group>
          ))
        )}
      </Inner>
    </Wrapper>
  );
};
