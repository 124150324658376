import { Editor, FloatingMenu } from '@tiptap/react';
import { styled } from 'buttered';
import PlusIcon from 'remixicon-react/AddLineIcon';

let PlusWrapper = styled('div')`
  margin-left: -45px;
`;

let PlusButton = styled('button')`
  height: 24px;
  width: 24px;
  border-radius: 50%;
  border: var(--vapor-border);
  background: var(--vapor-background);
  color: var(--vapor-accent-5);
  transition: all .3s;

  &:hover, &:focus {
    border: var(--vapor-border-primary);
    color: var(--vapor-accent-8);
  }

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export let PlusMenu = ({ editorRef }: { editorRef: React.RefObject<Editor | undefined> }) => {
  let editor = editorRef.current;
  if (!editor) return null;

  return (
    <FloatingMenu className="plus-menu" tippyOptions={{ duration: 200 }} editor={editor}>
      <PlusWrapper>
        <PlusButton aria-label="Open Block Menu" onClick={() => editor && editor.commands.insertContent('/')}>
          <div>
            <PlusIcon size={14} />
          </div>
        </PlusButton>
      </PlusWrapper>
    </FloatingMenu>
  )
}