import { Extension } from '@tiptap/core'
import { Editor } from '@tiptap/react'
import Suggestion, { SuggestionOptions } from '@tiptap/suggestion'

export let Commands = Extension.create<{
  suggestion: Partial<SuggestionOptions> & {
    items?: (q: string) => {
      title: string;
      icon: React.ReactElement;
      command: ({ editor, range }: { editor: Editor, range: Range }) => void
    }[][],
  }
}>({
  name: 'block-menu-provider',

  defaultOptions: {
    suggestion: {
      char: '/',
      startOfLine: false,
      command: ({ editor, range, props }: any) => {
        props.command({ editor, range })
      },
    },
  },

  addProseMirrorPlugins() {
    return [
      Suggestion({
        editor: this.editor,
        ...this.options.suggestion,
      }),
    ]
  },
})