import { NodeViewWrapper, NodeViewProps } from '@tiptap/react';
import { useMemo } from 'react';
import { providers } from './providers';

export let EmbedComponent = (props: NodeViewProps) => {
  let type = props.node.attrs.type;
  let src = props.node.attrs.src;

  let provider = useMemo(() => providers.find(p => p.type == type), [type]);

  return (
    <NodeViewWrapper className="embed">
      <div
        style={{
          background: 'var(--vapor-accent-2)',
          border: 'var(--vapor-border)',
          borderRadius: 'var(--vapor-radius-big)',
          overflow: 'hidden',
          display: 'grid',
          minHeight: 500,
          gridTemplateRows: ' 1fr 30px'
        }}
      >
        <div>
          <iframe
            sandbox="allow-same-origin allow-scripts allow-forms"
            allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            src={src}
            frameBorder={0}
            style={{ width: '100%', height: '100%' }}
          />
        </div>

        <aside
          style={{
            display: 'flex',
            padding: '7px 12px 6px 12px',
            borderTop: 'var(--vapor-border)'
          }}
        >
          <img
            src={provider?.logo}
            alt={`${provider?.name} Logo`}
            style={{
              height: 16,
              marginRight: 10
            }}
          />
          <p
            style={{
              fontSize: 14
            }}
          >
            {provider?.name || ''}
          </p>
        </aside>
      </div>
    </NodeViewWrapper>
  );
};
